import { PaginatedList } from '@/backend/models/Common'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'

export function useVariableSizeInfiniteLoader<T>(opts: {
  key: string
  size: number
  initialItems: T[]
  nextPageFetcher: (size: number, cursor: string) => Promise<PaginatedList<T[]>>
  getInitialCursor: (lastItem: T) => string
}) {
  const lastItem = useMemo(() => {
    const items = opts.initialItems
    // eslint-disable-next-line
    const size = opts.size

    return items.slice(0, size)[size - 1] || null
  }, [opts])
  const initialCursor = lastItem ? opts.getInitialCursor(lastItem) : null
  const [enabled, setEnabled] = useState(false)

  const { data: pages, ...infiniteQuery } = useInfiniteQuery<
    PaginatedList<T[]>
  >({
    enabled,
    initialPageParam: initialCursor,
    getNextPageParam: (lastPage) => lastPage.meta.nextCursor,
    queryKey: [opts.key, { size: opts.size }],
    queryFn: async ({ pageParam = null }) => {
      if (pageParam === null)
        return {
          data: [],
          meta: {
            nextCursor: null,
          },
        }

      return opts.nextPageFetcher(opts.size, pageParam as any)
    },
  })

  const fetchNextPage = () => {
    if (!enabled) {
      setEnabled(true)
    }

    return infiniteQuery.fetchNextPage()
  }

  return {
    fetchNextPage,
    isFetchingNextPage: infiniteQuery.isFetchingNextPage,
    hasNextPage: enabled ? infiniteQuery.hasNextPage : Boolean(initialCursor),
    items: pages?.pages.flatMap((page) => page.data) || [],
  }
}
