'use client'

import Accordion from '@/app/_components/Section/FAQSection/Accordion'
import Category from '@/app/_components/Section/FAQSection/Category'
import { useEffect, useState } from 'react'
import Star from '../../Icon/Star'

export default function FAQSection({
  categories,
  questions,
  backgroundColor,
  currentCategoryId,
  starFill,
}: {
  categories: { id: number; name: string }[]
  questions: { id: number; title: string; content?: string }[]
  backgroundColor: `french-bleu` | `butterscotch`
  currentCategoryId: number
  starFill: string
}) {
  const [openQuestionId, setOpenQuestionId] = useState<number | null>(null)

  useEffect(() => {
    const questionIdFromHash = parseInt(window.location.hash.slice(1), 10)
    setOpenQuestionId(
      !Number.isNaN(questionIdFromHash) ? questionIdFromHash : null,
    )
  }, [])

  useEffect(() => {
    const handleHashChange = () => {
      const questionIdFromHash = parseInt(window.location.hash.slice(1), 10)
      setOpenQuestionId(
        !Number.isNaN(questionIdFromHash) ? questionIdFromHash : null,
      )
    }

    window.addEventListener('hashchange', handleHashChange)
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  return (
    <div className="container ">
      <div className="flex w-full flex-col items-center gap-32 py-140 pb-80">
        <Star fill={starFill || '#3366B6'} />
        <div className="flex flex-col items-center">
          <p className="c-h5 font-noto-sans-kr lg:c-h4">Frequently Asked</p>
          <p className="a-h3 font-retro-chimps lg:a-h1">QUESTIONS</p>
        </div>
        <div className="inline-flex flex-wrap items-center justify-center gap-24 whitespace-nowrap max-lg:gap-10">
          {categories.map((category) => (
            <Category
              key={category.id}
              id={category.id}
              name={category.name}
              isActive={currentCategoryId === category.id}
              backgroundColor={backgroundColor}
            />
          ))}
        </div>
        <ul className="lg:max-w-600 xl:min-w-856">
          {questions.map((question) => (
            <li
              key={question.id}
              className="text-white underline transition-transform duration-300 ease-in-out hover:-translate-y-5 hover:shadow-xl">
              <Accordion
                question_id={question.id}
                title={question.title}
                content={question.content || ''}
                isOpen={openQuestionId === question.id}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
