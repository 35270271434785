'use client'

import OpenCollaboCard from '@/app/_components/Collabo/OpenCollaboCard'
import I18nTranslator from '@/app/_components/Shared/I18nTranslator/I18nTranslatorClient'
import { useGetCollaborations } from '@/app/_hooks/useGetCollaborations'
import { Button } from '@/app/_primitives/Button'
import { SubHeader } from '@/app/_primitives/SubHeader'
import { cn } from '@/app/utils'
import { PaginatedCollaborationOpening } from '@/backend/models/CollaborationOpening'
import Image from 'next/image'
import Link from 'next/link'
import ArrowDown from '../../Icon/ArrowDown'

function useLimit() {
  // const isLg = useMediaQuery({
  //   query: '(min-width: 768px)',
  // })

  if (typeof window === 'undefined') return 12

  // return isLg ? 11 : 6
  return 8
}

export default function OpenCollabo({
  initialData,
  isLoggedIn,
}: {
  initialData: PaginatedCollaborationOpening
  isLoggedIn?: boolean
}) {
  const limit = useLimit()
  const data = initialData.data.slice(0, limit)
  const { items, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetCollaborations({
      initialData: initialData.data,
      limit,
      type: 'all',
    })
  return (
    <div className="relative">
      <div className="container flex flex-col pb-120 max-lg:pb-0">
        <SubHeader
          className={'relative mb-34 mt-19 w-full'}
          variant={'dark'}
          customLink={
            <div className="flex items-center gap-8 max-lg:hidden">
              <div className="c-b2 font-[400] leading-[140%] text-[#B4B4B4]">
                {<I18nTranslator text="common.collaboBrand" />}
              </div>

              <div className="c-h6 text-french-bleu underline underline-offset-4">
                <Link href={'/collabo/create'}>
                  {<I18nTranslator text="common.collaboRegist" />}
                </Link>
              </div>
            </div>
          }>
          {<I18nTranslator text="common.toOpenCollabo" />}
        </SubHeader>
        <div className="flex flex-col items-center">
          <div className="relative grid gap-24 max-lg:flex max-lg:w-full max-lg:flex-col max-lg:items-center max-lg:justify-center lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {data.slice(0, 3).map((collabo) => (
              <OpenCollaboCard
                key={collabo.id}
                collaboration={collabo}
                isLoggedIn={isLoggedIn!}
              />
            ))}
            <SearchCharacterCard />
            {data.slice(3).map((collabo) => (
              <OpenCollaboCard
                key={collabo.id}
                collaboration={collabo}
                isLoggedIn={isLoggedIn!}
              />
            ))}
            {items.map((collabo) => (
              <OpenCollaboCard
                key={collabo.id}
                collaboration={collabo}
                isLoggedIn={isLoggedIn!}
              />
            ))}
            {/* <INFCard isSmall={true} /> */}
          </div>
          {hasNextPage && (
            <button
              type="button"
              className="c-b2 mt-32 flex items-center gap-4"
              disabled={isFetchingNextPage}
              onClick={() => {
                if (hasNextPage && !isFetchingNextPage) return fetchNextPage()
              }}>
              <div className="text-french-bleu">
                {<I18nTranslator text="common.viewMoreLabel" />}
              </div>
              <ArrowDown width={24} height={24} fill="#3366B6" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export function SearchCharacterCard(props: { className?: string }) {
  return (
    <Link
      href={'/collabo/create'}
      className={cn(
        'relative order-2 flex h-388 w-300 flex-col justify-between overflow-hidden rounded-16 bg-butterscotch shadow-card-mobile hover:shadow-xl max-lg:h-96 max-lg:w-full max-lg:px-5 max-lg:py-10 lg:p-24 xl:shadow-card-web',
        props.className,
      )}>
      <div
        className={
          'max-lg:flex max-lg:items-center max-lg:gap-28 lg:w-300 lg:overflow-hidden 2xl:row-start-2'
        }>
        <Image
          src={'/ad_vector.svg'}
          alt="프로젝트 올리기"
          width="300"
          height="275"
          className="pointer-events-none -bottom-10 right-0 max-lg:!h-98 max-lg:!w-112 lg:absolute"
        />
        <div className={'relative z-10 flex h-full flex-col justify-between'}>
          <div className="max-lg:pt-10">
            <p className="c-b4 text-[#696969] lg:c-b3">브랜드에 꼭 맞는</p>
            <p className="b-b1 text-french-bleu lg:b-h3">캐릭터 찾는법</p>
          </div>
        </div>
      </div>
      <Button
        type="button"
        size="default"
        color="white"
        className="c-b3 h-56 w-144 border-1 bg-transparent text-white hover:text-[#1a1a1a] max-lg:hidden">
        <Link href={'/collabo/create'}>프로젝트 올리기</Link>
      </Button>
    </Link>
  )
}
