import { SvgIconProps } from '@/types/helpers'

export default function ArrowDown(props: SvgIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.5 4C13.5 3.44772 13.0523 3 12.5 3C11.9477 3 11.5 3.44772 11.5 4V18.0858L6.70711 13.2929C6.31658 12.9024 5.68342 12.9024 5.29289 13.2929C4.90237 13.6834 4.90237 14.3166 5.29289 14.7071L11.0858 20.5C11.8668 21.281 13.1332 21.281 13.9142 20.5L19.7071 14.7071C20.0976 14.3166 20.0976 13.6834 19.7071 13.2929C19.3166 12.9024 18.6834 12.9024 18.2929 13.2929L13.5 18.0858V4Z"
        fill="currentFill"
      />
    </svg>
  )
}
