import { useVariableSizeInfiniteLoader } from '@/app/_hooks/useVariableSizeInfiniteLoader'
import { fetchCollaborations } from '@/backend/apis/collaboration/GetCollaborationsAPI'
import { GetCollaborationsOpts } from '@/backend/data/collaboration/CollaborationDAO.contract'
import { PaginatedCollaborationOpening } from '@/backend/models/CollaborationOpening'
import { encodeCursor } from '@/libraries/cursor'
// import dayjs from 'dayjs'

export function useGetCollaborations(opts: {
  initialData: PaginatedCollaborationOpening['data']
  limit: number
  type: GetCollaborationsOpts['type']
}) {
  return useVariableSizeInfiniteLoader({
    key: `collabo-${opts.type}`,
    size: opts.limit,
    initialItems: opts.initialData,
    getInitialCursor: (item) => {
      return encodeCursor({
        lastRecommendedRank: item.recommendedRank,
      })
    },
    nextPageFetcher: async (size, cursor) => {
      const response = await fetchCollaborations({
        limit: size,
        cursor,
        type: opts.type,
      })

      if (response.status === 'error')
        throw new Error(response.error || 'unknown error')

      return response.data
    },
  })
}
