import { z } from 'zod'

export function decodeCursor<T>(schema: z.Schema<T>, base64: string) {
  try {
    const decoded = Buffer.from(base64, 'base64').toString('utf8')
    return schema.parse(JSON.parse(decoded))
  } catch (error) {
    return null
  }
}

export function encodeCursor(object: any) {
  const json = JSON.stringify(object)
  return Buffer.from(json, 'utf8').toString('base64')
}
