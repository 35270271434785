'use client'

import ChevronForward from '@/app/_components/Icon/ChevronForward'
import { cn } from '@/app/utils'
import { useCurrentLocale, useI18n } from '@/locales/client'
import { clsx } from 'clsx'
import Link from 'next/link'
import React from 'react'

type Props = {
  className?: string
  titleClassName?: string
  linkClassName?: string
  children: string | React.ReactNode
  count?: string

  href?: string
  variant?: 'default' | 'dark'
  customLink?: React.ReactNode
}

export function SubHeader({
  titleClassName,
  className,
  linkClassName,
  children,
  count,
  href,
  customLink,
  variant = 'default',
}: Props) {
  const t = useI18n()
  return (
    <div
      className={cn('flex flex-row items-center justify-between', className)}>
      <div
        className={cn('flex flex-1 flex-row flex-wrap items-center gap-10', {
          'flex-none': useCurrentLocale() === 'en',
        })}>
        <p
          className={cn(
            {
              'text-black': variant === 'dark',
              'text-white': variant === 'default',
            },
            titleClassName,
            'c-h6 lg:c-h5',
          )}>
          {children}
        </p>
        {count !== undefined && (
          <p className={'c-b3 text-[#B1B1B1] lg:c-b2'}>
            {Number(count).toLocaleString()}
            <span>{t('common.countCharacter')}</span>
          </p>
        )}
      </div>

      {customLink !== undefined && customLink}
      {customLink === undefined && href !== undefined && (
        <Link
          href={href}
          className={cn('flex items-center gap-6 z-10', linkClassName)}>
          <p
            className={clsx('more c-b3 lg:c-b2', {
              'text-french-bleu': variant === 'dark',
              'text-white': variant === 'default',
            })}>
            {t('common.viewMoreLabel')}
          </p>
          <ChevronForward
            className={
              variant === 'dark' ? ' more fill-french-bleu' : 'more fill-white'
            }
          />
        </Link>
      )}
    </div>
  )
}
